import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import iframeResizer from 'iframe-resizer';
import Vuelidate from 'vuelidate';

// import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faLocationArrow,
  faCheck,
  faBars,
  faTimes,
  faChevronDown,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faLocationArrow, faCheck, faBars, faTimes, faChevronDown, faCheckCircle);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(iframeResizer);
Vue.use(Vuelidate);
Vue.use(VueReCaptcha, { siteKey: '6LegBvgpAAAAAJYyPV25btR9A5XgJaofu2ipPgNJ', loaderOptions: { useEnterprise: true } });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
