<template>
  <div id="app">
    <div
      class="fixed-container"
      v-if="isSpinnerLoading"
    >
      <LoadingSpinner />
    </div>
    <div
      v-if="!isAccessible"
      class="accessible-warning-screen"
      v-text="accessibilityMessage"
    />
    <div v-else-if="textsLoaded">
      <AppHeader />
      <router-view />
      <AppFooter />
      <LayoutCookieNotification />
      <WarningMessageModal
        v-if="$store.state.showErrorWarning"
        :title="$store.state.errorTitle"
        :text="$store.state.errorMessage"
      />
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';
import AppHeader from '@/components/navigation/AppHeader';
import AppFooter from '@/components/navigation/AppFooter';
import WarningMessageModal from '@/components/modals/WarningMessageModal';
import LayoutCookieNotification from '@/components/layout/CookieConsent';
import agent from './api/agent';

export default {
  name: 'App',
  data() {
    return {
      isSpinnerLoading: true,
      textsLoaded: false,
      isAccessible: false,
      accessibilityMessage: '',
    };
  },
  components: {
    LoadingSpinner,
    AppHeader,
    AppFooter,
    WarningMessageModal,
    LayoutCookieNotification,
  },
  created() {
    this.checkAccessibility();
    window.addEventListener('load', () => {
      this.loadIframeResizer();
    });
    if (this.$route.query?.compact) {
      this.$store.commit('SET_COMPACT_STATE', true)
    }
  },

  methods: {
    initializeApp() {
      this.$store.dispatch('fetchSettings');
      this.$store.dispatch('fetchLanguages');
      this.$store.dispatch('fetchTexts').then(() => {
        this.textsLoaded = true;
      });
      this.$store.dispatch('fetchProducts');

      const token = localStorage.getItem('token');

      if (token) {
        this.$store.dispatch('autoLogin', token);
      }
    },

    checkAccessibility() {
      let urlParams = new URLSearchParams(window.location.search);
      const previewCode = urlParams.get('previewCode');

      if (previewCode) {
        localStorage.setItem('previewCode', previewCode);
      } else {
        localStorage.removeItem('previewCode'); // Ensure no old preview code is enabled
      }

      agent.accessibility.getAccessibility().then((response) => {
        this.isSpinnerLoading = false;
        this.isAccessible = response?.isAccessible;
        this.accessibilityMessage = response?.message;

        if (response.isAccessible) {
          this.initializeApp();
        }
      });
    },

    loadIframeResizer() {
       window.iFrameResizer = {
        heightCalculationMethod: 'bodyOffset',
        onReady: function () {
          console.log(window.parentIFrame)
          console.log('iframe-resizer ready-v4')
        },
      }
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

@import '@/global-styles/mixins';
@import '@/global-styles/variables';
@import '@/global-styles/cre8ion-simplegrid';
@import '@/global-styles/flex';
@import '@/global-styles/default';
@import '@/global-styles/forms';
</style>

<style scoped>
.accessible-warning-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 3rem;
  font-weight: 700;
}
</style>
